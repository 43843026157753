import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image';
import SimpleReactLightbox, { SRLWrapper, useLightbox } from "simple-react-lightbox";

const Gallery = ({ gallery, reverse }) => {
    const [active, setActive] = useState(false)
    const { openLightbox } = useLightbox()
    const lightBoxImages = gallery && gallery.large.map(({ title, fluid }, index) => ({
        title,
        src: fluid.src,
        thumbnail: gallery && gallery.small[index].fluid.src
    }))



    return (

        <div className={`relative h-full flex w-full mx-auto justify-center`} style={{ minHeight: 400 }}>
            {gallery && <SRLWrapper options={{
                buttons: {
                    showAutoplayButton: false,
                    showDownloadButton: false,
                    showThumbnailsButton: false,
                }
            }} images={lightBoxImages} />}
            {
                gallery && gallery.small && gallery.small.map((image, index) => {

                    const { aspectRatio } = image.fluid
                    const vert = aspectRatio < 1
                    const len = gallery.small.length
                    const mid = Math.floor(len / 2)

                    const widths = "w-1/2 w-1/3 w-1/4 w-1/5 w-1/6 w-1/7 w-1/8 w-1/12"
                    return <>
                        <div
                            key={image.title + index}
                            className={`${aspectRatio < 1 ? `w-1/${len}` : "w-2/3"} hidden md:flex transition-all duration-500 absolute mx-4 self-center ${index === active || active !== false && 'opacity-10'}`}
                            style={{
                                marginLeft: `${!reverse ? "-" : ""}${(index) * ((vert ? 35 : 20) / len)}%`,
                                marginTop: `${(index) * (vert ? 4 : 4)}%`,
                                zIndex: index === active ? 99 : len - index,
                                transform: index === active ? 'scale(1.1)' : ""
                            }}

                            onMouseEnter={() => setActive(index)}
                            onClick={() => openLightbox(index)}
                            onMouseOut={() => setActive(false)}>
                            <Img
                                className="w-full"
                                alt={image.title}
                                fluid={image.fluid}
                            />
                        </div>
                        <div
                            className={`${aspectRatio < 1 ? `w-1/${len}` : "w-2/3"} md:hidden flex transition-all duration-500 absolute my-12 mx-4 self-center ${index === active || active !== false && 'opacity-10'}`}
                            style={{
                                marginLeft: `${(index - mid) * (vert ? 45 : 30) / len}%`,
                                marginTop: `${(index) * (vert ? 7.5 : 5)}%`,
                                zIndex: index === active ? 30 : len - index,
                            }}

                            onClick={() => openLightbox(index)}
                        >
                            <Img
                                className="w-full"
                                alt={image.title}
                                fluid={image.fluid}
                            />
                        </div>
                    </>
                })

            }
        </div>
    )
}

Gallery.propTypes = {

}

export default Gallery
