import React from 'react'
import Layout from '../components/layout/Layout';
import FancyHeader from '../components/elements/FancyHeader';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import RichText from '../components/rich-text/RichText';
import Gallery from '../components/elements/Gallery';
import SimpleReactLightbox from 'simple-react-lightbox';
import SEO from '../components/SEO';
import FancyLink from '../components/elements/FancyLink';

const Designer = ({ data }) => {
    const images = data?.allFile.nodes
    const page = data?.allContentfulPage.nodes[0]
    const { title, description: { text } } = page
    const { content } = page

    const Paper = images.filter(image => image.name === "paper-designer")[0]
    const Chair = images.filter(image => image.name === "chair-designer")[0]
    return (
       
        <Layout>
             <SEO 
          title="Freelance Designer"
          description="Whether designing beautiful interfaces or creating evocative branding Sam enjoys the creative process when it comes to designing new things for clients. Find out more and see some of his previous projects here."
        />
            <section className="flex relative bg-black"  >
                <Img style={{ position: 'absolute', opacity: .9 }} className="absolute h-full w-full top-0" fluid={Paper.childImageSharp.fluid}></Img>
                <div className="flex md:flex-row flex-col max-w-screen-lg mx-auto py-12" style={{ minHeight: 450 }}>
                    <div className="md:w-1/2 px-4 relative z-10 order-2 md:order-1 self-center text-white">
                        <FancyHeader >{title}</FancyHeader>
                        <p>{text}</p>
                        
                    </div>
                    <div className="w-2/3 md:w-1/2 self-center md:order-2 order-1">
                        <Img className="w-1/2 mx-auto" fluid={Chair.childImageSharp.fluid}></Img>

                    </div>
                </div>
            </section>
            <section>
                <h2 className="font-heading text-center px-4 mt-6 md:mb-6">Here is some of my previous work</h2>
            </section>
            {content && content.map((content, index) => <SimpleReactLightbox key={content.title+index} style={{ zIndex: 100 }} ><Project reverse={index % 2 === 0} {...content} /></SimpleReactLightbox>)}
        </Layout>
    )
}


const Project = ({ title, reverse, description, gallery, link, ...rest }) => {

    return <section
    data-aos="fade-up"
    data-aos-duration="2000"
    key={title} className="max-w-screen-lg mx-auto md:my-8  px-4 ">
     
     {/* <h2 className="hidden md:block font-heading">{title}</h2> */}
        <div className="grid md:grid-cols-2 grid-rows-1">
            <div className={` ${reverse ? "order-1" : "order-2"}`}>
            <h2 className="block font-heading">{title}</h2>
                <RichText text={description} className="space-y-4" />
                {link && <FancyLink linkTo={link.linkTo} className="font-normal">{link.title}</FancyLink>}

            </div>
            <div className={`w-full ${reverse ? "md:order-2" : "order-1"} `}  >
                <Gallery gallery={gallery} reverse={reverse} />
            </div>
        </div>
    </section>

}




export const DesignerPageQuery = graphql`
    query DesignerPageQuery {
        allFile(filter: {relativeDirectory: {eq: "pages/designer"}}) {
        nodes {
            name
            childImageSharp {
            fluid(maxWidth:1600, quality:95) {
                ...GatsbyImageSharpFluid
                }
            }
        }
        }
        allContentfulPage(filter: {title: {eq: "Designer"}}) {
            nodes {
                description {
                    text: description
                }
                title
                content {
                    gallery: images {
                        large: images {
                            fluid(maxWidth: 1600, quality:90) {
                                ...GatsbyContentfulFluid
                            }
                            title
                        }
                        small: images {
                            fluid(maxWidth: 300, quality:80) {
                                ...GatsbyContentfulFluid
                            }
                            title
                        }
                        title
                    }
                    link {
                    linkTo
                    title
                    }
                    title
                    description {
                        raw
                    }   
                }
            }
        }
    }
`
export default Designer

